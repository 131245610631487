<template>
  <v-app id="app">
    <Navigation v-if="!['Login'].includes($route.name) && userDataInMemory" />
    <v-main>
      <Content />
    </v-main>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </v-app>
</template>

<script>
import Content from "@/components/template/Content";
import ConfirmDialog from "@/components/template/ConfirmDialog";
import ErrorDialog from "@/components/template/ErrorDialog";
import Navigation from "@/components/template/Navigation";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Content,
    ConfirmDialog,
    ErrorDialog,
    Navigation,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations("user", ["setUser"]),
    checkUserLogged() {
      if (!this.userDataInMemory) {
        const userDataInLocalStorage = JSON.parse(
          localStorage.getItem("userData")
        );

        if (userDataInLocalStorage) {
          this.setUser(userDataInLocalStorage);
        } else {
          this.$router.push({ path: "/login" });
        }
      }
    },
  },
  computed: {
    ...mapState("user", {
      userDataInMemory: (state) => state.userData,
    }),
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirmDialog.open;
    this.$root.$errorDialog = this.$refs.errorDialog.open;
  },
  created() {
    this.checkUserLogged();
  },
};
</script>


<style>
</style>