const state = {
  userData: null,
};

const mutations = {
  setUser(state, user) {
    state.userData = user;
  },
  clearUser(state) {
    state.userData = null;
  },
};

const actions = {
  async signin({ commit }, accessData) {
    const response = await this._vm.$axios.post("users/signin", accessData);

    const data = { ...response.data };

    //Salvando no armazenamento local
    localStorage.setItem("userData", JSON.stringify(data));

    //Salvando no store (memória)
    commit("setUser", data);
  },
  async signout({ commit }) {
    await this._vm.$axios.post("users/signout");

    //Salvando no armazenamento local
    localStorage.removeItem("userData");

    //Salvando no store (memória)
    commit("setUser", null);
  },
  async signup() {
    // const response = await this._vm.$axios.post(
    //   "/advertisers/signup",
    //   userData
    // );
    // const data = { ...response.data };
    // //Salvando no armazenamento local
    // localStorage.setItem("userData", JSON.stringify(data.userData));
    // //Salvando no store (memória)
    // commit("setUser", data.userData);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
