const state = {
  scheduling: null,
};

const mutations = {
  setSchedulingData(state, data) {
    const cpData = { ...data };

    cpData.createdAt = this._vm.$moment.utc(cpData.createdAt).format("YYYY/MM/DD - hh:mm:ss");

    cpData.dateOfRealization = this._vm.$moment(cpData.startDate).format("DD/MM/YYYY");

    cpData.startDate = this._vm.$moment.utc(cpData.startDate).format("HH:mm");

    cpData.endDate = this._vm.$moment.utc(cpData.endDate).format("HH:mm");

    if (cpData.status === "confirmed") cpData.status = "Confirmado";
    else if (cpData.status === "waiting-for-service") cpData.status = "Aguardando atendimento";
    else if (cpData.status === "in-progress") cpData.status = "Em atendimento";
    else if (cpData.status === "patient-did-not-attend") cpData.status = "Paciente não atendido";
    else if (cpData.status === "canceled") cpData.status = "Cancelado";
    else if (cpData.status === "completed") cpData.status = "Paciente atendido";

    const payments = cpData.PaymentMethods;

    if (payments.length && payments.length > 1) {
      let paymentsAux = payments.map((p) => {
        return p.SchedulingPaymentMethod.paymentType === "in-cash"
          ? `(R$ ${parseFloat(p.SchedulingPaymentMethod.value)
              .toFixed(2)
              .replace(".", ",")}) ${p.name}`
          : `(R$ ${parseFloat(p.SchedulingPaymentMethod.value)
              .toFixed(2)
              .replace(".", ",")}) Parcelado (${p.SchedulingPaymentMethod.numberOfInstallments}x)`;
      });

      cpData.paymentMethodsName = paymentsAux.join(", ");

      const total = parseFloat(cpData.total);

      let totalPaid = 0.0;

      for (const p of payments) {
        if (p.SchedulingPaymentMethod.status === "paid")
          totalPaid += parseFloat(p.SchedulingPaymentMethod.value);
      }

      cpData.paymentStatus = totalPaid < total ? "Em aberto" : "Pago";
    } else if (cpData.PaymentMethods.length === 1) {
      //verifica o nome do pagamento
      cpData.paymentMethodsName = `(R$ ${parseFloat(
        cpData.PaymentMethods[0].SchedulingPaymentMethod.value
      )
        .toFixed(2)
        .replace(".", ",")}) ${cpData.PaymentMethods[0].name}`;

      //verifica o status do pagamento
      if (cpData.PaymentMethods[0].SchedulingPaymentMethod.status === "paid")
        cpData.paymentStatus = "Pago";
      else if (cpData.PaymentMethods[0].SchedulingPaymentMethod.status === "pending")
        cpData.paymentStatus = "Pendente";
      else if (cpData.PaymentMethods[0].SchedulingPaymentMethod.status === "canceled")
        cpData.paymentStatus = "Cancelado";
    } else {
      //se nao tiver pagamento incluso
      cpData.paymentMethodsName = "Não incluído";
    }

    if (cpData.Agreement && cpData.Agreement.receipt === "on-schedule")
      cpData.paymentMethodsName += ", *Restante pelo convênio";

    state.scheduling = cpData;
  },
};

const actions = {
  async loadScheduleData({ commit }, schedulingId) {
    const response = await this._vm.$axios.get(`/schedules/${schedulingId}`);

    commit("setSchedulingData", response.data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
