import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import moment from "./plugins/moment";
import store from "./store/index";
import axios from "./plugins/axios";

Vue.config.productionTip = false;

Vue.use(axios);
Vue.use(moment);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
