export default [
  {
    path: "/agendamentos",
    name: "Schedules",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Schedules" */ "../views/Schedules/Schedules.vue"),
  },
  {
    path: "/agendamentos/cadastrar",
    name: "InsertSchedules",
    props: { mod: "insert", title: "Criar novo agendamento" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertSchedules" */ "../views/Schedules/SchedulesForm.vue"
      ),
  },
  {
    path: "/agendamentos/:id",
    name: "UpdateSchedules",
    props: { mod: "update", title: "Atualizar agendamento" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateSchedules" */ "../views/Schedules/SchedulesForm.vue"
      ),
  },
];
