const state = {
  examFormData: {},
  loadingExamData: false,
};

const mutations = {
  initForm(state, data) {
    state.examFormData = data;
  },
  setLoadingFlag(state, value) {
    state.loadingExamData = value;
  },
};

const actions = {
  async setExamFormData({ commit }, schedulingProcedureId) {
    commit("setLoadingFlag", true);

    const response = await this._vm.$axios.get(
      `/medical-reports/procedures/${schedulingProcedureId}`
    );
    const copyOfData = { ...response.data };
    const dataToInitForm = {};

    //fotmata data e hora
    dataToInitForm.examDate = this._vm
      .$moment(copyOfData.Scheduling.dateOfTheConclusion)
      .format("YYYY-MM-DD");
    dataToInitForm.examHour = this._vm
      .$moment(copyOfData.Scheduling.dateOfTheConclusion)
      .format("HH:mm");

    dataToInitForm.examMode = copyOfData.Procedure.ExamReference.ExamMode.name;

    let auxPerson = copyOfData.Scheduling.Patient.Person;

    dataToInitForm.Person = {
      email: auxPerson.email,
      fantasyName: auxPerson.fantasyName,
      id: auxPerson.id,
      fullName: auxPerson.fullName,
      taxDocument: auxPerson.taxDocument,
      type: auxPerson.type,
      TypeOfLink: auxPerson.TypeOfLink,
      Patient: {
        PersonId: auxPerson.id,
        id: copyOfData.Scheduling.Patient.id,
      },
    };

    // console.log(dataToInitForm);

    commit("initForm", dataToInitForm);
    commit("setLoadingFlag", false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
