import moment from "moment";
import moneyHelper from "../../helpers/money_helper";

const formProps = {
  id: null,
  value: "0.0",
  status: "paid",
  paymentType: "in-cash",
  numberOfInstallments: null,
  dueDate: null,
  payDay: null,
  regarding: null,
  nameOfResponsible: null,
  taxDocumentOfResponsible: null,
  PaymentMethodId: null,
  SchedulingId: null,
};

const state = {
  form: { ...formProps },
  paymentMethods: [],
  mode: null,
};

const mutations = {
  clearForm(state) {
    state.form = { ...formProps };
  },
  clearStoreData(state) {
    state.form = { ...formProps };
    state.paymentMethods = [];
    this.mode = null;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods;
  },

  setFormData(state, { key, value }) {
    // console.log("Set value form: ");
    // console.log(`Key: ${key}   Value: ${value}`);
    state.form[key] = value;
  },
  initForm(state, initialData) {
    const cpData = JSON.parse(JSON.stringify(initialData));

    // console.log(initialData);
    // console.log(state.mode);

    Object.assign(state.form, cpData);
  },
};

const actions = {
  async vLoadSchedulePaymentData({ commit }, { scheduleId, paymentId }) {
    const response = await this._vm.$axios.get(`/schedules/${scheduleId}/payments/${paymentId}`);

    commit("initForm", response.data);
  },
  async vDeleteSchedulePaymentData(_, { scheduleId, paymentId }) {
    const response = await this._vm.$axios.delete(`/schedules/${scheduleId}/payments/${paymentId}`);

    return response.data;
  },
  async vLoadPaymentScheduleData({ commit }, scheduleId) {
    const response = await this._vm.$axios.get(`/schedules/${scheduleId}`);
    const { Patient, total } = response.data;

    const nameOfResponsible = Patient.Person.fullName;

    const todayDate = moment().format("YYYY-MM-DD");

    const data = {
      nameOfResponsible,
      taxDocumentOfResponsible: Patient.Person.taxDocument,
      SchedulingId: scheduleId,
      regarding: `Atendimento ao paciente ${nameOfResponsible}`,
      value: total,
      Scheduling: response.data,
      dueDate: todayDate,
      payDay: todayDate,
    };

    commit("initForm", data);
  },
  async vLoadPaymentMethods({ commit }) {
    const response = await this._vm.$axios.get(`/payment-methods`);

    commit("setPaymentMethods", response.data);
  },
  async vInsert({ state }, scheduleId) {
    const { value, ...remainingData } = state.form;

    const dataToSend = {
      value: moneyHelper.parseDecimal(value),
      ...remainingData,
    };

    const response = await this._vm.$axios.post(`/schedules/${scheduleId}/payments`, dataToSend);

    return response.data;
  },
  async vUpdate({ state }, { scheduleId, paymentId }) {
    const { value, ...remainingData } = state.form;

    const dataToSend = {
      value: moneyHelper.parseDecimal(value),
      ...remainingData,
    };

    const response = await this._vm.$axios.put(
      `/schedules/${scheduleId}/payments/${paymentId}`,
      dataToSend
    );

    return response.data;
  },
};

const getters = {
  getFormData: (state) => (key) => {
    // console.log("Get form value:");
    // console.log(`Key: ${key}   Value: ${state.form[key]}`);
    return state.form[key];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
