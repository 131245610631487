export default [
  {
    path: "/convenios",
    name: "Agreements",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Agreements" */ "../views/Agreements/Agreements.vue"
      ),
  },
  {
    path: "/convenios/cadastrar",
    name: "InsertAgreements",
    props: { mod: "insert", title: "Cadastrar convênios" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertAgreements" */ "../views/Agreements/AgreementsForm.vue"
      ),
  },
  {
    path: "/convenios/:id",
    name: "UpdateAgreements",
    props: { mod: "update", title: "Atualizar convênios" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateAgreements" */ "../views/Agreements/AgreementsForm.vue"
      ),
  },
];
