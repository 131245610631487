<template>
  <v-list>
    <div v-for="item in computedItems" :key="item.title">
      <v-list-group
        :prepend-icon="item.icon"
        :value="true"
        v-if="item.subitems && item.subitems.length"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>

        <v-list-item
          v-for="subitem in item.subitems"
          :key="subitem.title"
          :to="{ path: subitem.route }"
          exact
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ subitem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-else :to="{ path: item.route }" exact link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    items: [
      {
        title: "Página inicial",
        route: "/",
        icon: "mdi-home",
      },
      {
        title: "Assinatura",
        route: "/medico/assinatura",
        specialization: "doctor",
        icon: "mdi-draw",
      },
      {
        title: "Logs de acesso",
        route: "/logs-de-acesso",
        icon: "mdi-account-reactivate",
        permissionKey: "get-all-logs",
      },
      {
        title: "Meus exames",
        route: "/paciente/exames",
        specialization: "patient",
        icon: "mdi-file-document-multiple",
      },
      {
        title: "Agendamentos",
        route: "/agendamentos",
        icon: "mdi-calendar-month",
        permissionKey: "get-all-schedules",
      },
      {
        title: "Exames",
        specialization: "doctor",
        permissionGroupKey: "exams",
        checkAllNestedPermissions: true,
        icon: "mdi-file-document-multiple",
        subitems: [
          {
            title: "Exames",
            route: "/exames",
            icon: "",
          },
          {
            title: "Submissão de exames agendados",
            route: "/agendamentos/procedimentos",
            icon: "",
          },
        ],
      },
      {
        title: "Exames",
        specialization: "technician",
        permissionGroupKey: "exams",
        checkAllNestedPermissions: true,
        icon: "mdi-file-document-multiple",
        subitems: [
          {
            title: "Exames",
            route: "/tecnico/exames",
            icon: "",
          },
        ],
      },
      {
        title: "Cadastros",
        icon: "mdi-clipboard-text",
        subitems: [
          {
            title: "Filiais/Matriz",
            route: "/filiais",
            icon: "",
            permissionGroupKey: "company-branches",
            checkAllNestedPermissions: true,
          },
          {
            title: "Convênios",
            route: "/convenios",
            icon: "",
            permissionGroupKey: "agreements",
            checkAllNestedPermissions: true,
          },
          {
            title: "Máscaras",
            route: "/mascaras",
            icon: "",
            permissionGroupKey: "masks",
            checkAllNestedPermissions: true,
          },
          {
            title: "Pessoas",
            route: "/pessoas",
            icon: "",
            permissionGroupKey: "people",
            checkAllNestedPermissions: true,
          },
          {
            title: "Usuários",
            route: "/usuarios",
            icon: "",
            permissionGroupKey: "users",
            checkAllNestedPermissions: true,
          },
          {
            title: "Perfis de acesso",
            route: "/usuarios/perfis",
            icon: "",
            permissionGroupKey: "access-profiles",
            checkAllNestedPermissions: true,
          },
          {
            title: "Procedimentos",
            route: "/procedimentos",
            icon: "",
            permissionGroupKey: "procedures",
            checkAllNestedPermissions: true,
          },
          {
            title: "Métodos de pagamento",
            route: "/metodos-de-pagamento",
            icon: "",
            permissionGroupKey: "payment-methods",
            checkAllNestedPermissions: true,
          },
        ],
      },
      {
        title: "Relatórios",
        icon: "mdi-chart-box",
        permissionGroupKey: "reports",
        checkAllNestedPermissions: true,
        subitems: [
          {
            title: "Exames",
            route: "/relatorios/exames",
            icon: "",
            permissionKey: "get-all-reports",
          },
          {
            title: "Agendamentos",
            route: "/relatorios/agendamentos",
            icon: "",
            permissionKey: "get-all-reports-1",
          },
        ],
      },
    ],
  }),
  computed: {
    ...mapState("user", ["userData"]),
    computedItems() {
      const filteredItems = [];

      for (const item of this.items) {
        let formatedItem;
        let ok = this.checkPermissionItem(item);

        if (!ok) continue;

        if (item.subitems) {
          let formatedSubitems = [];

          for (const subitem of item.subitems) {
            let ok = this.checkPermissionItem(subitem);
            if (ok) formatedSubitems.push(subitem);
          }

          //Se não sobrar nenhum subitem, passa para o próximo item sem adiciona-lo
          if (!formatedSubitems.length) continue;

          formatedItem = {
            ...item,
            subitems: formatedSubitems,
          };
        } else {
          formatedItem = { ...item };
        }

        filteredItems.push(formatedItem);
      }

      return filteredItems;
    },
  },
  methods: {
    checkPermissionItem(item) {
      /**
       * Se a especialização do usuário for diferente da especialização exigiga
       * no menu, significa que não tem permissão.
       */

      const specializationModelName = String(
        this.userData.typeOfLink.modelName
      ).toLowerCase();

      if (
        item.specialization &&
        item.specialization !== specializationModelName
      )
        return false;

      //Se não tiver nenhum das duas propriedades, não verifica nada
      if (!item.permissionKey && !item.permissionGroupKey) return true;

      let indexFound = -1;

      /**
       * Se tiver as propriedades checkAllNestedPermissions && permissionGroupKey
       * Verifica se o usuário tem pelo menos uma permissão relacionada ao grupo
       * de permissões relacionado a este item do menu.
       */
      if (item.checkAllNestedPermissions && item.permissionGroupKey) {
        indexFound = this.userData.accessProfile.Permissions.findIndex(
          (p) => p.PermissionGroup.key === item.permissionGroupKey
        );
      } else {
        /**
         * Caso contrário, apenas ver se o usuário tem a permissão relacionada a este item do menu .
         */
        indexFound = this.userData.accessProfile.Permissions.findIndex(
          (p) => p.key === item.permissionKey
        );
      }

      return indexFound !== -1;
    },
  },
};
</script>

<style scoped></style>
