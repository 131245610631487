export default [
  {
    name: "Procedures",
    path: "/procedimentos",
    component: () =>
      import(
        /* webpackChunkName: "Procedures" */ "../views/Procedures/Procedures.vue"
      ),
  },
  {
    name: "InsertProcedures",
    path: "/procedimentos/cadastrar",
    component: () =>
      import(
        /* webpackChunkName: "InsertProcedures" */ "../views/Procedures/ProcedureForm.vue"
      ),
    props: { mod: "insert", title: "Cadastrar novo procedimento" },
  },
  {
    name: "UpdateProcedures",
    path: "/procedimentos/:id",
    component: () =>
      import(
        /* webpackChunkName: "UpdateProcedures" */ "../views/Procedures/ProcedureForm.vue"
      ),
    props: { mod: "update", title: "Atualizar procedimento" },
  },
];
