export default [
  {
    path: "/filiais",
    name: "CompanyBranches",

    component: () =>
      import(
        /* webpackChunkName: "CompanyBranches" */ "../views/CompanyBranches/CompanyBranches.vue"
      ),
  },
  {
    path: "/filiais/cadastrar",
    name: "CreateCompanyBranch",
    props: { title: "Cadastrar filial", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateCompanyBranch" */ "../views/CompanyBranches/CompanyBranchForm.vue"
      ),
  },
  {
    path: "/filiais/:id",
    name: "EditCompanyBranch",
    props: { title: "Editar filial", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditCompanyBranch" */ "../views/CompanyBranches/CompanyBranchForm.vue"
      ),
  },
];
