export default [
  {
    path: "/filiais/:id/configuracoes",
    name: "CompanyBranchConfigs",
    component: () =>
      import(
        /* webpackChunkName: "CompanyBranchConfigs" */ "../views/CompanyBranchConfigs/CompanyBranchConfigs.vue"
      ),
  },
];
