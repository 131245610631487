import VueRouter from "vue-router";
import Vue from "vue";

import agreements from "./agreements";
import masks from "./masks";
import people from "./people";
import users from "./users";
import companyBranches from "./company_branches";
import companyBranchAssociations from "./company_branch_associations";
import companyBranchConfigs from "./company_branch_configs";
import procedures from "./procedures";
import schedules from "./schedules";
import reports from "./reports";
import paymentMethods from "./payment_methods";
import medicalReports from "./medical_reports";
import exams from "./exams";
import examPartnertship from "./exam_partnership";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
    },
    {
      path: "/login",
      name: "Login",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
    },
    ...agreements,
    ...masks,
    ...people,
    ...users,
    ...companyBranchAssociations,
    ...companyBranchConfigs,
    ...companyBranches,
    ...procedures,
    ...exams,
    ...schedules,
    ...reports,
    ...paymentMethods,
    ...medicalReports,
    ...examPartnertship,
    {
      name: "AccessLogs",
      path: "/logs-de-acesso",
      component: () =>
        import(/* webpackChunkName: "AccessLogs" */ "../views/AccessLogs.vue"),
    },
    {
      name: "UpdateDoctorSignature",
      path: "/medico/assinatura",
      component: () =>
        import(
          /* webpackChunkName: "UpdateDoctorSignature" */ "../views/Exams/Doctor/Signature/SignatureForm.vue"
        ),
      props: { mod: "update", title: "Configurar assinatura" },
    },
    {
      path: "/ramificacoes/:partnershipId",
      name: "PartnershipBranches",
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "PartnershipBranches" */ "../views/PartnershipBranches/PartnershipBranches.vue"
        ),
    },
    {
      path: "/ramificacoes/cadastrar/:partnershipId",
      name: "InsertPartnershipBranches",
      props: {
        mod: "insert",
        title: "Cadastrar nova ramificação de Empresa/Órgão conveniado",
      },
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "InsertPartnershipBranches" */ "../views/PartnershipBranches/PartnershipBranchesForm.vue"
        ),
    },
    {
      path: "/ramificacoes/:id/parceiro/:partnershipId",
      name: "UpdatePartnershipBranches",
      props: {
        mod: "update",
        title: "Editar ramificação de Empresa/Órgão conveniado",
      },
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "UpdatePartnershipBranches" */ "../views/PartnershipBranches/PartnershipBranchesForm.vue"
        ),
    },
  ],
});

export default router;
