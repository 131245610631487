export default [
  {
    path: "/relatorios/exames",
    name: "ExamReports",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ExamReports" */ "../views/Reports/Exams/Exams.vue"
      ),
  },
  {
    path: "/relatorios/agendamentos",
    name: "SchedulesReports",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "SchedulesReports" */ "../views/Reports/Schedules/Schedules.vue"
      ),
  },
];
