export default [
  {
    name: "People",
    path: "/pessoas",
    component: () =>
      import(/* webpackChunkName: "People" */ "../views/People/People.vue"),
  },
  {
    name: "InsertPerson",
    path: "/pessoas/cadastrar",
    component: () =>
      import(
        /* webpackChunkName: "InsertPerson" */ "../views/People/PersonForm.vue"
      ),
    props: { mod: "insert", title: "Cadastrar pessoas" },
  },
  {
    name: "UpdatePerson",
    path: "/pessoas/editar/:id",
    component: () =>
      import(
        /* webpackChunkName: "UpdatePerson" */ "../views/People/PersonForm.vue"
      ),
    props: { mod: "update", title: "Editar cadastro de pessoas" },
  },
];
