<template>
  <div id="navigation">
    <v-navigation-drawer v-model="drawer" app>
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon large>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ userName }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-btn plain x-small class="pa-0">
                {{ accessProfile.name }}
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon :loading="loading">
              <v-icon @click="logout">mdi-logout-variant</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <Menu />
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title> Sistema Multimagem R.A </v-toolbar-title>
    </v-app-bar>
  </div>
</template>

<script>
import Menu from "@/components/template/Menu";
import errorHandler from "@/helpers/error_handler";
import { mapActions, mapState } from "vuex";

export default {
  components: { Menu },
  data: () => ({ drawer: null, loading: false }),
  methods: {
    ...mapActions("user", ["signout"]),
    async logout() {
      try {
        this.loading = true;
        
        await this.signout();

        this.$router.push("/login");
      } catch (error) {
        console.log(error);

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    accessProfile() {
      return this.userData.accessProfile;
    },
    userName() {
      if (!this.userData) return null;
      else if (this.userData.type === "legal-person")
        return this.userData.fantasyName;
      else if (this.userData.type === "physical-person")
        return this.userData.fullName;
      else return null;
    },
  },
};
</script>

<style></style>
