export default [
  {
    path: "/usuarios",
    name: "Users",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Users/Users.vue"),
  },
  {
    path: "/usuarios/cadastrar",
    name: "InsertUser",
    props: { mod: "insert", title: "Cadastrar pessoas" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertUser" */ "../views/Users/UserForm.vue"
      ),
  },
  {
    path: "/usuarios/editar/:id",
    name: "UpdateUser",
    props: { mod: "update", title: "Editar cadastro de usuários" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateUser" */ "../views/Users/UserForm.vue"
      ),
  },
  {
    path: "/usuarios/perfis",
    name: "UserProfiles",

    component: () =>
      import(
        /* webpackChunkName: "UserProfiles" */ "../views/UserProfiles/UserProfiles.vue"
      ),
  },
  {
    path: "/usuarios/perfis/cadastrar",
    name: "CreateUserProfile",
    props: { title: "Cadastrar perfil de usuário", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateUserProfile" */ "../views/UserProfiles/UserProfileForm.vue"
      ),
  },
  {
    path: "/usuarios/perfis/:id",
    name: "EditUserProfile",
    props: { title: "Editar perfil de usuário", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditUserProfile" */ "../views/UserProfiles/UserProfileForm.vue"
      ),
  },
];
