const formProps = {
  id: null,
  clinicalData: null,
  report: "",
  observations: null,
};

const state = {
  form: { ...formProps },
  examData: null,
  masks: [],
  vSelectedProcedure: null,
  vSelectedExamMode: null,
  mode: null,
};

const mutations = {
  clearForm(state) {
    state.form = { ...formProps };
  },
  clearState(state) {
    state.form = { ...formProps };
    state.examData = null;
    state.masks = [];
    state.vSelectedProcedure = null;
    state.vSelectedExamMode = null;
    state.mode = null;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setMasks(state, masks) {
    state.masks = [...masks];
  },
  setExamData(state, examData) {
    state.examData = { ...examData };
  },
  setFormData(state, { key, value }) {
    // console.log("Set value form: ");
    // console.log(`Key: ${key}   Value: ${value}`);
    state.form[key] = value;
  },
  setVSelectedProcedure(state, procedure) {
    state.vSelectedProcedure = procedure;
  },
  setVSelectedExamMode(state, examMode) {
    state.vSelectedExamMode = examMode;
  },
  initForm(state, initialData) {
    const cpData = JSON.parse(JSON.stringify(initialData));

    const auxForm = { ...formProps };

    Object.assign(auxForm, cpData);

    state.form = auxForm;
  },
};

const actions = {
  async vGetExamFileUrl(_, { examId, fileId }) {
    const response = await this._vm.$axios.get(`/exams/${examId}/exam-files/${fileId}`);

    return response.data.fileUrl;
  },
  async vLoadMedicalReportData({ commit }, { examId }) {
    const response = await this._vm.$axios.get(`/exams/${examId}/medical-report`);

    const copyOfData = { ...response.data };

    const { Exam, ...medicalReportData } = copyOfData;

    //Para fazer os dois objetos possuirem mesmo formato no publico e no privado
    const { ExamReference, SchedulingProcedure, type } = Exam;

    let procedureData;
    if (type === "public") {
      const { Procedure, ...remainingData } = ExamReference;

      procedureData = { ...Procedure, ExamReference: remainingData };
    } else {
      procedureData = SchedulingProcedure.Procedure;
    }

    // const examModeData =
    //   Exam.type === "public"
    //     ? Exam.ExamReference.ExamReference.ExamMode
    //     : Exam.SchedulingProcedure.Procedure.ExamReference.ExamMode;

    commit("setExamData", Exam);

    commit("initForm", medicalReportData);

    commit("setVSelectedProcedure", procedureData);

    // commit("setVSelectedExamMode", { text: examModeData.name, value: examModeData.id });
  },
  async vLoadExamData({ commit }, { examId }) {
    const response = await this._vm.$axios.get(`/exams/${examId}`);

    const examData = response.data;

    //Para fazer os dois objetos possuirem mesmo formato no publico e no privado
    const { ExamReference, SchedulingProcedure, type } = examData;

    let procedureData;
    if (type === "public") {
      const { Procedure, ...remainingData } = ExamReference;

      procedureData = { ...Procedure, ExamReference: remainingData };
    } else {
      procedureData = SchedulingProcedure.Procedure;
    }

    // const examModeData =
    //   examData.type === "public"
    //     ? examData.ExamReference.ExamMode
    //     : examData.SchedulingProcedure.Procedure.ExamReference.ExamMode;

    commit("setExamData", examData);

    commit("setVSelectedProcedure", procedureData);

    // commit("setVSelectedExamMode", { text: examModeData.name, value: examModeData.id });
  },
  async vLoadMasks({ commit }, { params }) {
    const response = await this._vm.$axios.get(`/exam-modes-masks`, { params });

    commit("setMasks", response.data);
  },
  async vInsert({ state }, { examId }) {
    const dataToSend = { ...state.form };

    const response = await this._vm.$axios.post(`/exams/${examId}/medical-report`, dataToSend);

    return response.data;
  },
  async vUpdate({ state }, { examId, status }) {
    const dataToSend = {
      ...state.form,
      status,
    };

    if (state.examData.type === "public") {
      dataToSend.ExamReferenceId = state.vSelectedProcedure.ExamReference.id;
    } else if (state.examData.type === "private") {
      dataToSend.ProcedureId = state.vSelectedProcedure.id;
    }

    const response = await this._vm.$axios.put(`/exams/${examId}/medical-report`, dataToSend);

    return response.data;
  },
  async vRequestResend(_, { examId }) {
    const dataToSend = { status: "requested-resend" };

    const response = await this._vm.$axios.put(`/exams/${examId}/status`, dataToSend);

    return response.data;
  },
};

const getters = {
  getFormData: (state) => (key) => {
    // console.log("Get form value:");
    // console.log(`Key: ${key}   Value: ${state.form[key]}`);
    return state.form[key];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
