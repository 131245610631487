export default [
  {
    path: "/filiais/:id/associacoes",
    name: "CompanyBranchAssociations",
    component: () =>
      import(
        /* webpackChunkName: "CompanyBranchAssociations" */ "../views/CompanyBranchAssociations/CompanyBranchAssociations.vue"
      ),
  },
];
