export default [
  {
    path: "/exames/cadastrar",
    name: "InsertExam",
    props: { type: "public", mod: "insert", title: "Cadastrar novo exame" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertExam" */ "../views/Exams/ExamForm.vue"
      ),
  },
  {
    path: "/agendamentos/:schedulingProcedureId/exames/cadastrar",
    name: "InserPrivatetExam",
    props: { type: "private", mod: "insert", title: "Cadastrar novo exame" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InserPrivatetExam" */ "../views/Exams/ExamForm.vue"
      ),
  },
  {
    path: "/exames/:id/editar",
    name: "UpdateExam",
    props: { mod: "update", title: "Editar exame" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateExam" */ "../views/Exams/ExamForm.vue"
      ),
  },
  // {
  //   path: "/agendamentos/:schedulingProcedureId/exames/editar/:id",
  //   name: "UpdatePrivateExam",
  //   props: { type: "private", mod: "update", title: "Editar exame" },
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "UpdatePrivateExam" */ "../views/Exams/ExamForm.vue"
  //     ),
  // },
  {
    name: "Exams",
    path: "/exames",
    component: () =>
      import(/* webpackChunkName: "Exams" */ "../views/Exams/Doctor/Exams.vue"),
  },
  {
    name: "ScheduleProceduresForSubmission",
    path: "/agendamentos/procedimentos",
    component: () =>
      import(
        /* webpackChunkName: "ScheduleProceduresForSubmission" */ "../views/Exams/Doctor/ScheduleProceduresForSubmission.vue"
      ),
  },
  {
    name: "TechnicianExams",
    path: "/tecnico/exames",
    component: () =>
      import(
        /* webpackChunkName: "TechnicianExams" */ "../views/Exams/Technician/Exams.vue"
      ),
  },
  {
    name: "PacientExams",
    path: "/paciente/exames",
    component: () =>
      import(
        /* webpackChunkName: "PacientExams" */ "../views/Exams/Patients/Exams.vue"
      ),
  },
];
