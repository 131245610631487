export default [
  {
    path: "/mascaras",
    name: "Masks",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Masks" */ "../views/Masks/Masks.vue"),
  },
  {
    path: "/mascaras/cadastrar",
    name: "InsertMasks",
    props: { mod: "insert", title: "Cadastrar máscaras" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertMasks" */ "../views/Masks/MasksForm.vue"
      ),
  },
  {
    path: "/mascaras/:id",
    name: "UpdateMasks",
    props: { mod: "update", title: "Atualizar máscaras" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateMasks" */ "../views/Masks/MasksForm.vue"
      ),
  },
];
