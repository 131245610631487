export default [
  {
    path: "/metodos-de-pagamento",
    name: "PaymentMethods",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PaymentMethods" */ "../views/PaymentMethods/PaymentMethods.vue"
      ),
  },
  {
    path: "/metodos-de-pagamento/cadastrar",
    name: "InsertPaymentMethods",
    props: { mod: "insert", title: "Cadastrar métodos de pagamentos" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertPaymentMethods" */ "../views/PaymentMethods/PaymentMethodsForm.vue"
      ),
  },
  {
    path: "/metodos-de-pagamento/:id",
    name: "UpdatePaymentMethods",
    props: { mod: "update", title: "Atualizar métodos de pagamentos" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdatePaymentMethods" */ "../views/PaymentMethods/PaymentMethodsForm.vue"
      ),
  },
];
