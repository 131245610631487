export default [
  {
    path: "/exames/:examId/laudo/cadastrar",
    name: "InsertMedicalReport",
    props: { mod: "insert", title: "Cadastrar laudo" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "InsertMedicalReport" */ "../views/MedicalReports/MedicalReportForm.vue"
      ),
  },
  {
    path: "/exames/:examId/laudo/editar",
    name: "UpdateMedicalReport",
    props: { mod: "update", title: "Editar/Visualizar laudo" },
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "UpdateMedicalReport" */ "../views/MedicalReports/MedicalReportForm.vue"
      ),
  },
];
