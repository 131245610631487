import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import schedule from "@/store/modules/schedule";
import schedulingReports from "@/store/modules/scheduling_reports";
import schedulePayment from "@/store/modules/schedule_payment";
import examForm from "@/store/modules/exam_form";
import medicalReport from "@/store/modules/medical_report";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    schedule,
    schedulingReports,
    schedulePayment,
    examForm,
    medicalReport,
  },
  strict: true,
});

export default store;
