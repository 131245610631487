import moment from "moment";

const formProps = {
  id: null,
  procedures: [],
  patient: null,
  startDate: moment().format("YYYY-MM-DD"),
  startTime: null,
  endTime: null,
  AgreementId: null,
  CompanyBranchId: null,
  discountInPercentageFormat: true,
  discount: 0.0,
};

const state = {
  form: { ...formProps },
  scheduleData: null,
  agreements: [],
  companyBranches: [],
  mode: null,
};

const mutations = {
  clearForm(state) {
    state.form = { ...formProps };
  },
  clearStoreData(state) {
    state.form = { ...formProps };
    state.agreements = [];
    state.companyBranches = [];
    this.mode = null;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setAgreements(state, agreements) {
    state.agreements = agreements;
  },
  setCompanyBranches(state, companyBranches) {
    state.companyBranches = companyBranches;
  },
  setFormData(state, { key, value }) {
    // console.log("Set value form: ");
    // console.log(`Key: ${key}   Value: ${value}`);
    state.form[key] = value;
  },
  initForm(state, initialData) {
    const cpData = JSON.parse(JSON.stringify(initialData));

    state.scheduleData = cpData;

    const { Procedures, Patient, startDate, discount, endDate, ...remainingData } = cpData;

    const auxForm = { ...formProps };

    const mStartDate = moment.utc(startDate);
    const mEndDate = moment.utc(endDate);

    const formatedData = {
      procedures: Procedures,
      startDate: mStartDate.format("YYYY-MM-DD"),
      startTime: mStartDate.format("HH:mm"),
      endTime: mEndDate.format("HH:mm"),
      patient: {
        fullName: Patient.Person.fullName,
        id: Patient.Person.id,
        Patient,
      },
      discount: parseFloat(discount) || 0.0,
      ...remainingData,
    };

    Object.assign(auxForm, formatedData);

    state.form = auxForm;
  },
};

const actions = {
  async vLoadScheduleData({ commit }, scheduleId) {
    const response = await this._vm.$axios.get(`/schedules/${scheduleId}`);

    commit("initForm", response.data);
  },
  async vLoadCompanyBranches({ commit }) {
    const response = await this._vm.$axios.get(`/company-branches`);

    commit("setCompanyBranches", response.data);
  },
  async vLoadAgreements({ commit }, { params }) {
    const response = await this._vm.$axios.get(`/agreements`, { params });

    commit("setAgreements", response.data);
  },
  async vInsert({ state }) {
    const { patient, procedures, ...remainingData } = state.form;

    const dataToSend = {
      PatientId: patient.Patient.id,
      procedures: procedures.map((p) => p.id),
      ...remainingData,
    };

    const response = await this._vm.$axios.post(`/schedules`, dataToSend);

    return response.data;
  },
  async vUpdate({ state }, scheduleId) {
    const { patient, procedures, ...remainingData } = state.form;

    const dataToSend = {
      PatientId: patient.Patient.id,
      procedures: procedures.map((p) => p.id),
      ...remainingData,
    };

    const response = await this._vm.$axios.put(`/schedules/${scheduleId}`, dataToSend);

    return response.data;
  },
  async vUpdateStatus({ commit }, { scheduleId, status }) {
    const response = await this._vm.$axios.put(`/schedules/${scheduleId}/status`, { status });

    commit("setFormData", { key: "status", value: status });

    return response.data;
  },
};

const getters = {
  getFormData: (state) => (key) => {
    // console.log("Get form value:");
    // console.log(`Key: ${key}   Value: ${state.form[key]}`);
    return state.form[key];
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
